@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .font-thin {
    font-weight: unset;
  }
}

body {
  /* background-color: #000300; */
  background-color: #fff;
  color: #5E5A59;
}

